.archives {
	position: relative;
	padding: 7.5rem 0;

	&__title {
		padding: 6rem 0 3rem 0;
		// padding: 3rem 0;
		display: flex;
		justify-content: center;

		@include media(">phone", "<tablet") {
			padding: 2rem 0;
		}

		@include media("<=phone") {
			padding: 1.5rem 0;
		}

		span {
			text-transform: uppercase;
			font-size: 3rem;
			font-weight: 700;
			color: #ffc300;

			@include media(">phone", "<tablet") {
				font-size: 2.5rem;
			}

			@include media("<=phone") {
				font-size: 1.8rem;
			}
		}
	}
}
