.home {
	position: relative;
	width: 100%;
	height: inherit;

	.video__container {
		position: relative;
		width: 100% !important;
		height: 100vh !important;
		overflow: hidden;
		object-fit: cover;
	}
}
