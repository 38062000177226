.project__item {
    position: relative;
    display: grid;
    grid-row-gap: 0.5rem;
    overflow: hidden;

    &--container {
        position: relative;
        cursor: pointer;
        height: 25rem;

        @include media("<=tablet") {
            height: 25vw;
        }

        @include media(">1100px", "<laptop") {
            height: 17rem;
        }

        @include media(">=laptop", "<1300px") {
            height: 19rem;
        }

        @include media(">=1300px", "<desktop") {
            height: 22rem;
        }

        @include media(">=LGdesktop") {
            height: 25rem;
        }

        .item__title {
            position: absolute;
            letter-spacing: 0.05rem;
            display: block;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 100%;
            height: 4rem;
            opacity: 0;
            transition: all 0.3s ease;
            font-family: $font-body;

            @include media("<=phone") {
                height: 3rem;
            }

            .title {
                font-weight: 500;
                color: #ffffff;
                font-size: 1.4rem;
                text-align: center;

                @include media("<=450px") {
                    font-size: 1.1rem;
                }
            }
        }

        .img__thumbnail {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: bottom;
            transition: all 0.3s ease;
        }

        .icon {
            &__set {
                position: absolute;
                bottom: 1.5rem;
                right: 1.5rem;
                display: grid;
                grid-row-gap: 1rem;
                opacity: 0;
                transition: all 0.3s ease;

                @include media("<phone") {
                    display: none;
                }
            }

            &__holder {
                display: grid;
                grid-template-columns: auto 2.5rem;
                align-content: center;
                grid-column-gap: 1.5rem;
                justify-items: end;
                transition: all 0.3s ease;

                svg {
                    display: inline-block;
                    height: 2.5rem;
                    width: 2.5rem;
                    fill: #bbb;
                }

                .svg-gallery {
                    height: 2.7rem;
                    width: 2.7rem;
                }

                &:hover {
                    svg {
                        fill: #fff;
                    }

                    .icon__holder--text {
                        color: #fff;
                        opacity: 1;
                        align-self: center;
                    }
                }

                &--text {
                    font-size: 1.3rem;
                    opacity: 0;
                    transition: all 0.3s ease;
                }
            }
        }
    }

    &:hover {
        .img__thumbnail {
            filter: brightness(25%);
        }

        .icon__set {
            opacity: 1;
        }

        .item__title {
            opacity: 1;
        }
    }
}
