.slide {
	display: flex;
	align-items: center;
	justify-content: center;

	&__preview {
		position: relative;
		width: 100% !important;
		height: 100vh !important;

		img {
			display: block;
			width: 100%;
			height: 100%;
			object-fit: cover;
			filter: brightness(80%);
		}
	}
}
