.footer {
	position: relative;

	&__description {
		position: relative;
		padding: 4rem 0;
		width: 100%;
		background-color: rgb(30, 30, 30);

		display: flex;
		justify-content: center;
		align-items: center;

		@include media("<tablet") {
			padding: 4rem 2rem;
			text-align: center;
		}

		&--text {
			font-size: 1.3rem;
			color: #fff;
			letter-spacing: 0.15rem;
		}
	}

	&__nav {
		position: relative;
		min-height: 5rem;
		padding: 0 4rem;

		display: grid;
		grid-template-columns: repeat(2, 1fr);
		align-content: center;

		@include media("<tablet") {
			padding: 0 2rem;
		}

		@include media("<=phone") {
			grid-template-rows: repeat(2, 1fr);
			grid-template-columns: 1fr;
			grid-row-gap: 1rem;
			justify-items: center;
		}

		&--header {
			font-size: 1.2rem;
			color: #222;
		}

		&--list {
			display: flex;
			justify-content: flex-end;
			list-style: none;

			&-item {
				font-size: 1.8rem;

				&:not(:last-child) {
					margin-right: 1.5rem;
				}

				a {
					&:link,
					&:visited {
						text-decoration: none;
						display: inline-block;
					}
				}
			}
		}
	}
}

.footer__social-icon {
	display: inline-block;
	height: 1.8rem;
	width: 1.8rem;
	fill: #ffc300;
}
