.project {
	position: relative;
	min-height: 92vh;
	width: 136.8rem;
	margin: 0 auto;

	@include media("<1100px") {
		width: 100%;
	}

	@include media("<phone") {
		padding: 0 2rem;
	}

	@include media(">=phone", "<tablet") {
		padding: 0 4rem;
	}

	@include media(">=tablet", "<1100px") {
		padding: 0 5rem;
	}

	@include media(">=1100px", "<1300px") {
		width: 92rem;
	}

	@include media(">=1300px", "<=desktop") {
		width: 114rem;
	}

	&__content {
		width: 100%;
		height: 100%;

		&-img {
			position: relative;
			width: 100%;
			height: 65rem;

			@include media("<phone") {
				height: 35vw;
			}

			@include media(">=phone", "<1100px") {
				height: 38vw;
			}

			@include media(">=1100px", "<1300px") {
				height: 45rem;
			}

			@include media(">=1300px", "<=desktop") {
				height: 55rem;
			}

			img {
				display: block;
				width: 100%;
				height: 100%;
				object-fit: contain;
			}
		}

		&-video {
			position: relative;
			width: 100%;
			height: 65rem;
		}
	}

	.video__icon {
		position: absolute;
		bottom: 2rem;
		right: 13rem;
		height: 3rem;
		width: 3rem;
		cursor: pointer;

		svg {
			display: inline-block;
			height: 100%;
			fill: $primary-grey;
			width: 100%;
			transition: all 0.4s ease-in-out;
		}
	}

	&__details {
		position: relative;
		margin: 5rem 11rem;

		@include media(">1300px", "<=desktop") {
			margin: 4rem 8rem;
		}

		@include media(">1100px", "<=1300px") {
			margin: 3rem 6rem;
		}

		@include media(">phone", "<=1100px") {
			margin: 3rem 11vw;
		}

		@include media("<phone") {
			margin: 2rem 13vw;
		}

		.label {
			display: grid;
			align-content: start;
			grid-row-gap: 1.5rem;
			margin-bottom: 3rem;

			&__holder {
				display: grid;
				grid-template-columns: auto 2.5rem;
				align-content: center;
				grid-column-gap: 1.5rem;
				justify-items: end;

				&--text {
					display: flex;
					align-items: center;
					font-size: 1.3rem;
					color: $primary-grey;
					cursor: pointer;
					transition: all 0.4s ease-in-out;
				}

				svg {
					display: inline-block;
					height: 2.3rem;
					width: 2.3rem;
					fill: $primary-grey;
					cursor: pointer;
					transition: all 0.4s ease-in-out;
				}

				.svg-gallery {
					height: 2.5rem;
					width: 2.5rem;
				}

				&:hover {
					.label__holder--text {
						color: $white;
					}

					svg {
						fill: $white;
					}
				}
			}
		}

		.details {
			border-top: 0.5px solid rgb(37, 37, 37);
			border-bottom: 0.5px solid rgb(37, 37, 37);

			.accordion {
				display: flex;
				justify-content: space-between;
				align-items: center;
				position: relative;
				margin: 2rem 0;
				cursor: pointer;

				&:hover {
					.accordion__btn {
						svg {
							fill: $white;
						}
					}
				}

				&__btn {
					position: relative;
					height: 3rem;
					width: 3rem;

					svg {
						fill: $primary-grey;
						height: 100%;
						width: 100%;
						transition: all 0.4s ease-in-out;
					}
				}
			}

			.credits {
				display: grid;
				grid-row-gap: 1.5rem;
				margin: 0;
				max-height: 0px;
				visibility: hidden;

				&__container {
					h4 {
						font-family: $font-body;
						font-weight: 400;
						font-size: 1.5rem;
						color: $white;
					}

					span {
						font-family: $font-typography;
						font-weight: 400;
						font-size: 1.6rem;
						color: $primary-grey;
					}
				}
			}
		}
	}
}

.swiper-button-prev,
.swiper-button-next {
	color: #ffc300;
}

.swiper-button-disabled {
	opacity: 0 !important;
}
