.modal {
	position: fixed;
	top: 0;
	left: 0;
	height: 100vh;
	width: 100vw;
	background: rgba(0, 0, 0, 0.98);
	overflow: hidden;
	transition: all 0.3s ease;
	z-index: 10;

	&__closed {
		display: none;
	}

	&__content {
		position: relative;
		display: none;
		align-content: center;
		justify-items: center;
		height: 100%;
		width: 100%;

		&--removeBtn {
			position: absolute;
			top: 4rem;
			right: 5rem;

			svg {
				display: block;
				height: 3rem;
				width: 3rem;
				fill: $primary-grey;
				transition: all 0.4s ease-in-out;
				cursor: pointer;

				&:hover {
					fill: $white;
				}
			}
		}

		&-video {
			position: relative;
			height: 60vh;
			width: 80vw;
		}
	}
}
