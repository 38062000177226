$primary-yellow: #ffc300;
$primary-grey: #888;
$black: #000;
$white: #fff;

$font-body: "Roboto", sans-serif;
$font-typography: "Barlow", sans-serif;

*,
*::before,
*::after {
    padding: 0;
    margin: 0;
    box-sizing: inherit;
}

html {
    font-size: 62.5%;
    box-sizing: border-box;

    &::-webkit-scrollbar {
        appearance: none;
        width: 1rem;
        background-color: #111;
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 100px;
        background-color: #333;
    }

    @include media("<tablet") {
        font-size: 56.5%;
    }
}

body {
    font-family: $font-body;
    background-color: $black;
    letter-spacing: 0.04rem;
    line-height: 1.3;
}

// typography
.heading {
    &__primary {
        font-family: $font-body;
        font-size: 4rem;
        font-weight: 900;
        color: $primary-yellow;
        text-transform: uppercase;
        margin-bottom: 3rem;
        line-height: 1.3;

        @include media(">1300px", "<desktop") {
            font-size: 3rem;
        }

        @include media(">1000px", "<1300px") {
            font-size: 2.7rem;
        }

        @include media(">phone", "<1000px") {
            font-size: 2.3rem;
        }

        @include media("<=phone") {
            font-size: 2rem;
        }

        span {
            text-transform: none !important;
        }
    }

    &__tertiary {
        font-family: $font-body;
        line-height: 1.3;
        color: $primary-yellow;
        text-transform: uppercase;
        font-size: 2rem;
        margin-bottom: 1rem;

        @include media("<=phone") {
            font-size: 1.7rem;
        }
    }
}

.paragraph {
    font-family: $font-typography;
    font-size: 1.6rem;

    color: $primary-grey;
    text-align: justify;
    line-height: 1.3;

    span {
        text-transform: uppercase;
        font-weight: 600;
        // color: $white;
    }

    .centered_bold {
        font-style: italic;
        display: flex;
        text-transform: none;
        justify-content: center;
    }

    &__last {
        font-style: italic;
        display: flex;
        justify-content: center;
        padding: 2rem 0;
        font-weight: 600;
        // color: $white;
    }
}

.btn {
    display: inline-block;
    font-size: 1.5rem;
    color: #ffc300;
    text-decoration: none;
    padding: 1rem 2.5rem;
    border: 1px solid #ffc300;
    border-radius: 4px;
    transition: all 0.3s ease-in-out;

    &:hover,
    &:focus {
        color: $white;
        border-color: $white;
    }
}
